.mainDiv {
    position: relative;
    z-index: 100 !important;
    max-width: 1000px;
    /* overflow-x: hidden; */
    /* overflow-x: auto; */
    @media screen and (max-width: 1024px) {
        /* max-width: 100%; */
        /* padding-bottom: 10px; */
        overflow-x: hidden;
        .smaller_screen_map_card{
            width: 250px !important;
            margin-right: 30px; 
            .credit{
                font-size: 13px !important;
            }
        }
    }
    @media screen and (max-width: 400px) {
        /* max-height: 400px; */
        /* padding-bottom: 10px; */
        /* overflow-x: hidden; */
        .smaller_screen_map_card{
            width: 220px !important;
            margin-right: 10px; 
            .credit{
                font-size: 13px !important;
            }
        }
        
    }
}
.items{
    color: white;
}
.feature_cards{
    padding-bottom: 50px !important;
}
.mobile_styles{
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}