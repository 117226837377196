.disclaimer-container{
    text-align: justify;
    margin-left: 1.8%;
    margin-right: 1.8%;
    padding: 6% 6% 0.5% 6%;
    
}

.contion-card{
    margin-left: 1%;
    margin-right: 4%;
    padding: 2% 0% 0.5% 3%;
}

.term-h{
    font-weight: 600;
}

@media (max-width: 500px) { 
    .contion-card{
    padding: 8% 0% 0.5% 3%;
    }

    .header{
        text-align: left;
    }


}