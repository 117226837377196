.chat-box-sticky .markdown>* {
    all: revert;
    text-align: left;
}

.chat-box-sticky p,
.chat-box-sticky a {
    word-wrap: break-word;
}

.chat-box-sticky a {
    color: #1d4ed8;
    text-decoration: underline;
}

/* carbonespace ai main gradient */
.chat-box-sticky .csai-common-grad {
    background-image: linear-gradient(to right, #13536C, #60AA94);
}

/* ai avtar gradient */
.chat-box-sticky .csai-agent {
    background-image: linear-gradient(to left, #60AA94, #13536C);
}

/* user avtar gradient */
.chat-box-sticky .csai-user {
    background-image: linear-gradient(to right, #13536C, #60AA94);
}

.chat-box-sticky .csai-common-text {
    color: #9CA3AFFF;
}

.chat-box-sticky label {
    color: #9CA3AF;
    margin-left: 12px;
    margin-bottom: 4px;
}

.chat-box-sticky .no-scrolling {
    overflow: hidden;
    resize: none;
}

.chat-box-sticky .userMessage p {
    text-align: end;
}

.chat-box-sticky .assistantMessage p {
    text-align: start;
}

.chat-box-sticky .error {
    bottom: -80%;
}

.chat-box-sticky .show-error {
    bottom: -70%;
}

/* width */
.chat-box-sticky ::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.chat-box-sticky ::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.chat-box-sticky ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
.chat-box-sticky ::-webkit-scrollbar-thumb:hover {
    background: #555;
}