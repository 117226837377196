//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
    background-color: #d7e4ec;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.viewtbl-row-class:hover {
  // font-weight: 600;
  cursor: pointer;
  color: red;
  float: "left";
  background-color: #d7e4ec !important;
}

.header-color {
  background-color: #d7e4ec !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  top: 0;
  z-index: 1;
}

.table-sdg-icon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}

// @media (max-height: 615px) {
// .table-margin{
// margin-top: 200px;
// }
// }
// #table-margin.scrolled {
//   margin-top: 500px;
// }
// .sticky-header {
//   position: sticky;
//   top: 500;
//   z-index: 1;

// }
// .table-margin.sticky-header {
//   margin-top: 500px;
// }

// .sticky-header {
//   position: sticky;
//   top: 320;
//   z-index: 1;
// }

.remove-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.padding {
  padding-left: 0px;
  padding-right: 0px;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

@media screen and (max-width: 500px) {
  .table-bottom-space {
    height: 50px;
  }
}

.load-more{
  color:#3F6A84;
  border: solid 1px #3F6A84;
}

.load-more:hover{
  background: rgb(144,178,199);
  border :solid 1px #9bd3f7;
}

.load-more{
  color:#3F6A84;
  border: solid 1px #3F6A84;
}

.load-more:hover{
  background: rgb(144,178,199);
  border :solid 1px #9bd3f7;
}

.load-more{
  color:#3F6A84;
  border: solid 1px #3F6A84;
}

.load-more:hover{
  background: rgb(144,178,199);
  border :solid 1px #9bd3f7;
}

.load-more{
  color:#3F6A84;
  border: solid 1px #3F6A84;
}

.load-more:hover{
  background: rgb(144,178,199);
  border :solid 1px #9bd3f7;
}

.page-item.active .page-link {
  background-color: #3F6A84;
  border-color: #3F6A84;
}
.page-item .page-link {
  font-size: 13px;
}

.table-container {
  height: 100%;
  overflow-y: auto;
  max-height: 700px; /* Default max height */

  @media (max-height: 670px) {
    max-height: 450px;
  }

  @media (min-height: 671px) and (max-height: 750px) {
    max-height: 470px;
  }

  @media (min-height: 751px) and (max-height: 830px) {
    max-height: 520px;
  }

  @media (min-height: 831px) and (max-height: 870px) {
    max-height: 560px;
  }

  @media (min-height: 871px) {
    max-height: 700px;
  }
}

.table-container thead {
  position: sticky;
  top: 0;
  background-color: white; 
  z-index: 1; 
}


.table-row-hover:hover {
  background-color: #F8F9FA; 
  cursor: pointer;
  font-weight: 600;
  color: #495057 !important;
  fill: #495057 !important;
}
.mapview_tooltip{
  .leaflet-tooltip-pane{
    background-color: #3F6A84;
  }
}
@media (max-width: 977px) {
  .larger_screen_map_card {
    display: none;
  }
}

@media (max-width: 978px) {
  .smaller_screen_map_card {
    display: block;
  }
}

@media (min-width: 977px) {
  .smaller_screen_map_card {
    display: none;
  }
}

@media (min-width: 978px) {
  .map_legend {
    max-height: fit-content;
  }
}