
  
  .mobile-dropdown-slider {
    position: relative;
    width: 100%;
    // padding-left: 5%;
  }
  
  .mobile-dropdown-slider__track,
  .mobile-dropdown-slider__range,
  .mobile-dropdown-slider__left-value,
  .mobile-dropdown-slider__right-value {
    position: absolute;
    
  }
  
  .mobile-dropdown-slider__track,
  .mobile-dropdown-slider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .mobile-dropdown-slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
    // padding-left: 5%;
  }
  
  .mobile-dropdown-slider__range {
    background-color: #7E8CBC;
    z-index: 2;

  }
  
  .mobile-dropdown-slider__left-value,
  .mobile-dropdown-slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }
  
  .mobile-dropdown-slider__left-value {
    left: 6px;
    
  }
  
  .mobile-dropdown-slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .mobile-dropdown-thumb,
  .mobile-dropdown-thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .mobile-dropdown-range-input-box{
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    line-height: 0px;
    height:44px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .mobile-dropdown-range-text{
    padding: 5px 0px 1px 5px;
    // font-size: 12px;
    // font-size: 0.8vw;
    font-size: 9px;
    color:#000000;
    // color:#949b9e;
    font-weight: 200; 
  }

  .mobile-dropdown-range-input{
    // font-size: 14px;
    font-size: 13px;
    border: none;
    height: 18.5px;
    // font-size: 1vw;
    width:100%;
    color:#8B8F92;
    padding: 0px 0px 1px 5px;
    
  }
  
  .mobile-dropdown-thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 85%;
    // padding-left: 5%;
    outline: none;
  }
  
  .mobile-dropdown-thumb--left {
    z-index: 3;
  }
  
  .mobile-dropdown-thumb--right {
    z-index: 4;
  }
  
  /* For Chrome browsers */
  .mobile-dropdown-thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .mobile-dropdown-thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  .mobile-dropdown-cre-available-box{
    display: inline-flex;
    width:90%;
  }
  
