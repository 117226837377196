.helpcenter-bg{
    background-image: url("../../../../assets/images/Help-Center.png");
    background-repeat: no-repeat;
    background-size: cover;
    // background-size: auto;                   
    // background-repeat:   no-repeat;
    // background-position: center center;  
    width: 100%;
    height: 90%;
    // background-attachment: fixed;
    // position: relative;
    // padding-top: 10px;
}

.topic{
    font-size: 18px;
    text-align: center;
    font-weight: 500;
}
// .heading{
//   font-size: 20px;
//   color:white;
// }
.heading-text{
  font-size: 15px;
  font-weight: 600;
}

.help-center{
    margin-left: 7%;
    margin-right: 7%;
    padding: 2% 6% 2% 6%;
    @media screen and (max-width: 581px) {
        margin-bottom: 20%;
      
    }
}
.faq-images{
    // margin-left:50px;
    width:70px;
    height:80px;
    object-fit: contain;
}

.image-box{
    width:100%;
    border-radius: 15px;
    border: 1px solid rgb(188, 197, 202);
    text-align: center;
    padding: 20px;
    //line-height: 30px;
}

.image-box:hover{
    background-color:rgb(88, 139, 170);
    color:white;
}
  
  .accordion {
    max-width: 100%;
    margin: 2rem auto;
  }
  
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #f3f9fa;
    font-size: 13px;
    font-weight: 600;
    line-height: 12px;
  }
  
//   .accordion-title:hover {
//     background-color: #a0a8aa;
//   }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  }
  
  .accordion-content {
    background-color: white;
    font-size: 13px;
  }

  .sub-point{
    margin:0px 0px 0px 3px;
    i{
        color: #556ee6;

    }
}
  
  // @media screen and (max-width: 700px) {
  //   body {
  //     font-size: 18px;
  //   }
  
  //   .accordion {
  //     width: 90%;
  //   }
  // }

