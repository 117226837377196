.landing-logo {
  padding-top: 5px;
}

.circle-dash {
  width: 100px;
}

.landing-title {
  font-size: 29px;
  font-weight: 600;
  color: #ffffff;
  // padding-top: 50px;
}

.landing-content {
  @media screen and (max-width: 768px) {
    padding-bottom: 20%;
  }
  .row {
    --bs-gutter-x: 0px;
  }

  .dropdown-item {
    color: #495057;
  }
}

.landing-subtitle {
  font-size: 22px;
  font-weight: normal;
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
}

.round-bg {
  width: 200px;
  height: 80px;
  border-radius: 60px;
  background: rgba(128, 160, 174, 0.75);
  // background-color: transparent;
  // background-color:#FFFFFF;
  display: flex;
  align-items: center;

  img {
    vertical-align: middle;
  }

  .col {
    text-align: left;
    margin: 0;
    position: relative;
  }

  .left-aligned-image {
    margin-left: -8px;
  }

  .image-text {
    width: 48px;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
  }

  .image-text2 {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0px;
  }
}

.landing-btn-search {
  height: 40px;
  width: 122px;
  background-color: #77b0c8;
  color: #ffffff;
  border-radius: 24px;
  border: none;
  font-size: 16px;
}

@media screen and (max-width: 1000px) {
  .landing-btn-search {
    height: 40px;
    width: 100px;
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .round-bg-image-content {
    top: 60% !important;
    left: 10% !important;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 450px) {
  .test2container {
    right: 20%; /* Adjust the desired value for smaller screens */
  }
}

.test2container {
  position: relative;
  width: 60px;
  padding-left: 30px;
  margin-left: 15px;
  text-align: left;
}

.test1container {
  position: absolute;
  top: 59%;
  // left: 26%;
  transform: translateY(-50%);
}

.landing-serch-bar {
  background-color: #ffffff;
  border-radius: 49px;
  padding: 10px 20px;
  margin: 3% 25% 1% 25%;
  height: 53px;
}

@media screen and (min-height: 700px) {
  .landing-logo {
    padding-top: 20px;
  }
  .landing-serch-bar {
    margin: 5% 25% 1% 25%;
  }

  .landing-title {
    padding-top: 40px;
  }
  .round-bg {
    width: 250px;
  }
  .circle-dash {
    width: 100px;
  }
}

@media screen and (min-width: 1501px) {
  .test2container {
    width: 135px;
    padding-left: 10px;
  }
}
@media screen and (min-width: 831px) and (max-width: 1500px) {
  .test1container {
    left: 30%;
  }
}
// @media screen and (min-width: 1001px) and (max-width: 15000px) {
//   .test1container {
//     left: 30%;
//   }
// }

@media screen and (min-width: 1501px) and (max-width: 4000px) {
  .test1container {
    left: 22%;
  }
}

/* Adjust for mobile */
@media screen and (max-width: 450px) {
  .landing-logo {
    padding-top: -10px;
  }
  .landing-serch-bar {
    margin: 10% 2% 1% 2%;
  }
  .landing-title {
    padding-top: 40px;
    font-size: 20px;
  }

  .landing-subtitle {
    font-size: 14px;
  }

  .round-bg {
    width: 180px;
    height: 65px;
  }

  .circle-dash {
    width: 80px;
    padding-top: 2px;
  }

  .test2container {
    width: 100px;
  }
  .test1container {
    left: 50%;
  }

  .image-text {
    font-size: 12px !important;
  }

  .landing-browse {
    margin: 0 2% 3% 5% !important ;
  }
}

.lan-btn2 {
  // border-radius: 50%;
  margin-top: -14px;
  color: rgb(88, 139, 170);
  // background-color: white;
  border: none;
  font-size: 25px;
  padding-right: 5px;
  cursor: pointer;
}

.text-search {
  background: transparent;
  border: none;
  padding-top: 0px;
}

.icon-only {
  font-size: 13px;
}

.icon-only > svg {
  display: inline-block; /* Show the SVG icon */
  width: 20px;
}

.landing-browse {
  font-size: 12px;
  font-weight: 400;
  color: #d0dae3;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 25% 3% 25%;
  background-color: transparent;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.landing-browse:hover {
  color: #a7dd9e;
}

.lantext-btn2 {
  color: #ffffff;
  margin-top: -14px;
  border: none;
  font-size: 12px;
  cursor: pointer;
}

@media screen and (min-width: 1920px) {
  .text-content-row {
    padding-top: 5%;
  }
}
