.tabsnew {
    width: 80%;
    height: auto;
    min-height: 400px;
    background: #053742;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 1rem;
    border-radius: 2rem;
    @media (max-width: 769px) {
     padding: 2rem 0;
    }
}

.fdv8m2z {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 4%;
    //width: calc(100% - var(--scrollbar-gutter,0%))
    margin-left:auto;
    margin-right: auto;
    width: fit-content;
    z-index: 8;
    // // pointer-events: none;
    display: grid;
    //grid-template: repeat(2,auto) / repeat(3,33.333%);
    // grid-template-areas:
    //      'left center right'
    //      'bottom bottom bottom';
    cursor: pointer;
    
   
}



.m1ioqu24 {
    justify-self: center;
    align-self: end;
}

._1pntom0 {
    z-index: 8 !important;
    white-space: nowrap !important;
    pointer-events: auto !important;
}

._174uh40 {
    -webkit-box-pack: center !important;
    -webkit-box-align: center !important;
    appearance: none !important;
    display: inline-flex !important;
    // border-radius: 24px !important;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    outline: none !important;
    margin: 0px !important;
    padding: 14px 19px !important;
    color: var(--f-k-smk-x) !important;
    cursor: pointer !important;
    touch-action: manipulation !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: none !important;
    transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
    background: rgb(34, 34, 34) !important;
    color:#FFFFFF;
}

._7u66d2 {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
    font-weight: 600 !important;
    display: flex !important;
    
}

._r16tng {
    display: inline-flex !important;
    align-self: center !important;
}


._hqsu3j {
    display: inline-flex !important;
    align-self: center !important;
    margin-left: 8px !important;
}

.nav-bg{
    background-color:#90b2c7;
    border-radius: 15px;
    width: 20vw;
    color: #FFFFFF;
    
}

.nav-link.active1{
    background-color:#3F6A84;
    border-radius: 15px;
    color:#FFFFFF;
    width: 100vw;
    height: 100%;
}

// .nav-link1{
//     color: #d6dde4;
//     font-weight: 500;
//     width: 20vw;
//     height: 100%;


// }
@media screen and (max-width: 856px) {
    .fdv8m2z {
            margin-bottom: 15%; 
        }
        }

