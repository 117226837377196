// 
// _footer.scss
// 
.footer {
    bottom: 0;
    padding: 13px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    // color:#74788d;
    left: 250px;
    height:6%;
    // height: $footer-height;
    background-color: $footer-bg;

    @media (max-width: 991.98px) {
        left: 0;
    }

    @media (max-width: 1080px) {
        padding-bottom: 45px;
    }

    
}

.footer-web{
    bottom: 0;
    padding: 13px calc(#{$grid-gutter-width} / 2);
    // padding-right: 0px;
    position: fixed;
    right: 0;
    color: $footer-color;
    // color:#74788d;
    left: 0px;
    height:6%;
    // height: $footer-height;
    background-color: $footer-bg;
    font-size: 11px;
    z-index: 1000;
    display: flex;
    align-items: center;

    @media (max-width: 991.98px) {
        left: 0;
        padding-bottom: 45px;

    }

    @media (max-width: 1080px) {
        padding-bottom: 45px;
    }

    @media (max-width: 536px) {
        // padding-top:20px;
        // padding-bottom: 50px;
        height:16%;

    }
 
}

@media screen and (max-width: 1080px) {
    .footer-horizontal-row {
      padding-top: 35px;
    }
  }
  
// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}

.onclick-fun{
    color:#74788d;
    
}

.footer-link{
    // padding-left: 12.5%;
    padding-left: 3%;
    text-align: center;

    a{
        color:#74788d;
    }
}

.carbon-copyright{
    padding-left: 5%;
}


@media (max-width: 925px) {
    .statement{
        display:none;
    }
    .carbon-copyright{
        display:none;

    }
    .social-media{
        display:none;
       
    }
    .copyright-tag{
       display: inline-flex;
       text-align: left;
    }
    .media-icon{
            display: inline-flex;
            text-align: right;
         }
    .social-media-icon{
            font-size: 13px;
         }

}



@media (max-width: 594px) {
    .remove-space{
        display:none;
    }
}



@media (max-width: 503px) {
    .remove-space-tag{
        display:none;
    }
}

@media (max-width: 470px) {
    .remove-space-tag1{
        display:none;
    }
}

@media (max-width: 420px) {
    .remove-space-tag4{
        display:none;
    }
}

@media (max-width: 385px) {
    .remove-space-tag2{
        display:none;
    }
}

@media (max-width: 390px) {
    .remove-space-tag2{
        display:none;
    }
}

@media screen and (min-width: 925px) {
    
    .footer-second {
        display: none;
    }

    .social-media{
        display:inline;
        text-align: right;       
    }

    .carbon-tag{
        display:inline;
    }
    
}


