//
// _header.scss
//

// #page-topbar {
//   position: fixed;
//   top: 0;
//   right: 0;
//   left: $sidebar-width;
//   z-index: 1002;
//   background-color: $header-bg;
//   box-shadow: $box-shadow;
//   @media (max-width: 991.98px) {
//     left: 0;
//   }
// }
#page-topbar{
  position: fixed;
  height: 55px;
  top: 0;
  right: 0;
  left: 0px;
  z-index: 1002;
  background-color: $header-bg;
  box-shadow: $box-shadow;
  @media (max-width: 991.98px) {
    left: 0;
  }
}
.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  
  // padding: 0 calc(#{$grid-gutter-width} / 2) 0 0 /*rtl: 0 0 0 calc(#{$grid-gutter-width} / 2) */;

  .dropdown {
    &.show {
      &.header-item {
        background-color: $gray-100;
      }
    }
    .dropdown-menu {
      margin-top: 0;
    }
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: $navbar-brand-box-width;
}

.logo {
  line-height: 70px;

  .logo-sm {
    display: none;
  }
}

.logo-light {
  display: none;
}

/* Search */

.app-search {
  @media (min-width: 1313px) {
    padding: calc(#{$header-height - 30px} / 2) 0;
    margin-right: 50%;
  }
  // margin-right: 50px;
  // margin-right: 50%;
  @media (max-width: 1313px) {
    display: none !important;
  }
  @media (min-width: 1794px) {
    margin-right: 30%;
    padding-left: 10%;
  }

  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

// @media (min-width: 993px) and (max-width: 1199.98px) {
@media (min-width: 993px) and (max-width: 1313px) {
  .serch-sm{
    // padding: calc(#{$header-height - 10%} / 2) 0;
    // margin-right: 60px;
    margin: 0px -282px 0px 0px;
    padding-left: 10%;
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  padding: calc(#{$header-height} + #{$grid-gutter-width})
    calc(#{$grid-gutter-width} / 2) $footer-height
    calc(#{$grid-gutter-width} / 2);
}


.brand-logo{
  margin-left: 40px;
  margin-bottom: 15px;
  
}
.projectbtn{
  margin-top: 12px;
  // overflow: hidden;

  .btn-check:focus + .green-btn, .green-btn:focus {
    color: #fff;
    background-color: #90BE83;
    // border-color: #90BE83;
    
  }

  .green-btn {
    width: 70px;
    font-weight: 700;
    display: inline-block;
    border-radius: 20px;
    background-color: #90BE83;
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 7px 15px 7px 15px;
    cursor: pointer;
    margin: 0px 20px 25px 20px;  
  }
  
  .green-btn:hover {
    background-color: #78af68; 
  }
}


// .green-btn span {
//   cursor: pointer;
//   display: inline-block;
//   position: relative;
//   transition: 0.5s;
// }

// .green-btn span:after {
//   content: '\00bb';
//   position: absolute;
//   opacity: 0;
//   top: 0;
//   right: -20px;
//   transition: 0.5s;
// }

// .green-btn:hover span {
//   padding-right: 15px;
// }

// .green-btn:hover span:after {
//   opacity: 1;
//   right: 0;
// }



.header-item {
  max-height: $header-height;
  height: 100%;
  width: 100%;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;
  position: relative;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.notification-item {
  .d-flex {
      padding: 0.75rem 1rem;
     

      &:hover {
          background-color: $gray-300;
      }
  }
}


// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-color: $border-color;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\ea3f";
    }
  }
}

body[data-topbar="dark"] {
  #page-topbar {
    background-color: $header-dark-bg;
  }
  #page-topbar-web {
    background-color: $header-dark-bg;
  }
  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    @media (max-width:"1100px") {
      display: none;
    }
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      color: $white;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}
.search-clear-btn {
  color: rgba(149, 149, 149, 0.66);
  padding: 7px 12px 7px 2px;
  font-size: 17px;
  font-weight: 600;
  background: none;
  border: none;
}

.search-clear-btn:disabled {
  color: #f3f3f3;  
}

.search-clear-btn:not(:disabled):hover {
  color: rgba(149, 149, 149, 0.66);
}


body[data-sidebar="dark"] {
  .navbar-brand-box {
    background: $sidebar-dark-bg;
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
    .exclude-dropdown {
      // Override the styles for the excluded part
      .dropdown-menu {
        left: auto !important;
        right: 0 !important;
        top: 125% !important;
        right: 10px !important;
      }
    }
  }
}


@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] {
  .navbar-brand-box {
    width: auto;
  }
  .page-content {
    margin-top: $header-height;
    padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 15px;
    }
  }
}


.btn-bar{
  border-radius: 25px;
  background-color:white;
  // border:1px solid rgb(238, 228, 228);
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;  height: 40px;
  // width:180%
  width:100%;
  max-width: 664px;
  margin-top: -27px;
  position:fixed;

}

.serch-sm{
  display: none;
}

// @media (max-width: 1145px) {
  @media (max-width: 1313px) {
  .btn-bar{
    opacity: 0;
  }
  .serch-sm{
    display: inline-block;
    
  }

  .search-icon-bar{
    // display: inline;
    display:inline-flex;
    margin-left: -375px;
    
  }
}

@media screen and (min-width: 1313px) {
  .search-icon-bar{
    display: none;
  }
}

.btn-group{
  display:inline-flex;
}

.input-col{
  max-width: 200px;
}
.input-col5{
  max-width: 18px
}

.input-btn-1{
  height: 75%;
  width: 155px;
  min-width: 155px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  // border:1px solid black;
  border:none;
  color: #495057;
  text-align: left;
  font-size: 11px;
  font-weight: 500;
  margin: 4px;
  padding-left: 3%;
  padding-top: 4px;
}

.clear-btn-1 {
  height: 75%;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: #cccccc;
  margin: 4px;
  padding-top: 1%;
}

.clear-btn-2 {
  // height: 75%;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: #cccccc;
  // margin: 4px;
  padding-top: 0;
}

.clear-btn-1:hover, .clear-btn-2:hover {
  color: #9b9a9a;
}


.search-btn-align{
  // margin-left: 120px;
  min-width: 117px;
}

.input-btn-1::placeholder { 
  color: #495057;
  font-weight: 500;
}

.input-btn-2{
  height:40px;
  width:100%;
  border:1px solid rgb(230, 222, 222);
  text-align: left;
  font-size: 11px;
  margin-left: 0px;
  
}

.input-btn-2::placeholder { 
  color: black;
  font-weight: 500;
}
  
// .input-btn:hover{
//   background-color: rgb(88, 139, 170);
//   color: white;
// }


.dropdown-btn-group {
  height:100%;
  font-size: 11px;
  z-index: 4;
}

.dropdown-btn-group-1{
  height:100%;
  font-size: 11px;
  padding: 0px;
}

.dropdown-btn-group-2{
  height:100%;
  font-size: 11px;
  z-index: 4;
  padding: 0px;
  
}

.dropdown-btn-group-3{
  height:100%;
  font-size: 11px;
  padding: 0px;
  
}

.minwidth{
  min-width: 120px;
  .css-1316qav-placeholder{
    top: 14px;
    color: #495057;
  }
  .css-8qhrgj-indicatorContainer{
    padding-top:6px;
    color: #495057;
    fill: #495057;
  }
  .css-8mmkcg{
    color: #495057;
    fill: #495057;
  }
  .select__indicators{
    color: #495057;
    fill: #495057;
  }
}

.customize-dropdown {
  height:100%;
  font-size: 10px;
  min-width: 134px;
}

.serch-btn-container{
  // float: right;
  display: flex;
  justify-content: flex-end;
}

.reset-btn-container{
  float: right;
  display: flex;
  justify-content: flex-end;
}

.search-btn1{
  height: 80%;
  border-radius: 20px;
  margin: 4px 10px 2px -70px ;
  padding-top: 6px;
  background-color:  rgb(88, 139, 170);
  font-size: 10px;
  text-align: center;
  border:none
}

.search-btn1:hover{
  background-color:  rgb(78, 120, 146);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
}

.search-bar{
  float: center;
}

.search-btn2{
  height: 80%;
  border-radius: 20px;
  margin: 4px 5px 2px 0px ;
  padding-top: 6px;
  background-color:  #588BAA;
  border-color: #588BAA ;
  font-size: 10px;
  text-align: center;
}

.search-btn2:hover {
  background-color: #3F6A84; 
  border-color: #3F6A84;
}

.reset-btn1{
  height: 80%;
  border-radius: 20px;
  margin: 4px 5px 2px -70px ;
  padding-top: 6px;
  background-color:  rgb(192, 37, 37);
  border-color: rgb(192, 37, 37) ;
  font-size: 10px;
  text-align: center;
}

.reset-btn2{
  height: 78%;
  border-radius: 20px;
  margin: 4px 5px 2px 0px ;
  padding-top: 6px;
  background-color:  white;
  border: 2px solid #5C7678;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
}

.reset-btn2:hover {
  background-color: #5C7678; 
  color: white; 
}

.dropdown-btn-group:hover {
  background-color: rgb(182, 212, 231); 
  cursor: pointer; 
}

.dropdown-btn-group-1:hover {
  background-color: rgb(182, 212, 231); 
  cursor: pointer;
}

.dropdown-btn-group-2:hover{
  background-color: rgb(182, 212, 231); 
  cursor: pointer;
}

.lan-btn{
  // border-radius: 50%;
  margin-top: -14px;
  color: rgb(88, 139, 170);
  // background-color: white;
  border:none;
  font-size: 25px;
  padding-right: 5px;
  cursor: pointer;
}

.lan-btn2{
  margin-top: -14px;
  color: rgb(88, 139, 170);
  border:none;
  font-size: 25px;
  padding-right: 5px;
  cursor: pointer;
}

.lantext-btn{
  // border-radius: 50%;
  margin-top: -14px;
  border:none;
  font-size: 12px;
  cursor: pointer;
}

.profile-btn{
  background-color: white;
  // margin-top: -50px;
  margin-bottom: 20%;
  color:#588BAA;
  border-radius: 30px;
  // font-size: 15px;
  font-size: 105%;
  display: inline-flex;
  // border: 2px solid  #90BE83;
  border: 2px solid  #588BAA;
  padding:5px 12px 3px 12px;
}

.credit-content-1{
  margin-top: 20px;
  background-color: #FFFFFF;
  padding: 15px;
  width:400px;
  border-radius: 10px;
}

.credit-content-2{
  margin-top: 20px;
  background-color: #FFFFFF;
  // padding: 15px;
  width:100%;
  border-radius: 10px;
}

.credit-dropdown-1{
  // height:38px;
  height:40px;
  width: 134px;
  padding: 8px;
  border-left: 1px solid  rgb(238, 228, 228);
  display: inline-flex;
  font-size: 11px;
}

.credit-dropdown-1:hover {
  background-color: rgb(182, 212, 231); 
  cursor: pointer;
  // padding-top: 1px; 
}

.credit-dropdown-2{
  height:38px;
  width:108%;
  padding: 3%;
  font-size: 10px;
  border:1px solid rgb(238, 228, 228);
  font-size: 11px;
  margin-left: -12px;
}

.css-tj5bde-Svg {
  color: #495057 !important;
}

@media screen and (max-width: 600px) {
  .credit-dropdown-2{
    height:38px;
    width:106%;
    padding: 3%;
    font-size: 10px;
    border:1px solid rgb(238, 228, 228);
    font-size: 11px;
    margin-left: -12px;
  }

  .dropdown-btn-group-1:hover {
    background-color: rgb(182, 212, 231); 
    cursor: pointer;
  }
  
  .dropdown-btn-group-2:hover{
    background-color: rgb(182, 212, 231); 
    cursor: pointer;
  }
}

.credit-dropdown-2:hover {
  background-color: rgb(182, 212, 231); 
  cursor: pointer; 
}

.gap{
  margin-bottom: 4px;
}

@media (max-width: 600px) {
  span.logo-sm {
    margin-left: -30px;
  }
}

.search-icon-bar{
  border-radius: 25px;
  background-color:white;
  border:1px solid rgb(189, 183, 183);
  // height: 40px;
  height: 35px;
  // height: 35px;
  // width:200px;
  width:25vw;
  margin-top: -16px;
  padding-top: 8px;
  margin-right: 8px;
  
  // padding-right: 200;
}

.mdi-icon{
  margin-right: 70px;
  margin-left: 10px;
  margin-top: -6px;
}

@media screen and (max-width: 550px) {
  .lan-btn{
    display: none;
  }

  .lantext-btn{
    display: none;
  }

  .list-project-tag{
    opacity: 0;
  }

  .projectbtn{
    padding-right: 0px;
  }

  .mdi-icon{
    margin-top: -8px;
  }
}

.search-icon-bar1{
  width:35px;
  // background-color: yellow;
  height: 25px;
  margin-top:1px;
  border-radius: 60px;
  display: inline-flex;
  
}

@media screen and (max-width: 1075px) {
  .tag-search{
    display:inline;
  }
}

@media screen and (max-width: 991px) {
  .tag-search{
    display:inline;
  }

  .mdi-icon{
    margin-right: 65px;
  }

  .search-icon-bar{
   
    margin-left:0px;
    
  }
}

@media screen and (max-width: 680px) {
  .tag-search{
    display:none;
  }

  .search-icon-bar{
    width: 15vw;
  }
}

// @media screen and (max-width: 500px) {
  
//   .search-icon-bar{
//     width: 4vw;
//     margin-right: 0px;
//     border: none;
//   }

//   .mdi-icon{
//     margin-left:-15px;
//     margin-right:1px; 
//   }
// }

// @media screen and (max-width: 500px) {
//   .search-icon-bar{
//     width: 5vw;
//   }
// }
.list-tag{
  display: inline-flex;
}

@media screen and (max-width: 450px) {
  .search-icon-bar{
    margin-left: 5px;
    height: 30px;
  }
}


.clear-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 88%;
  cursor: pointer;
}
.hide_large {
  @media screen and (min-width: 1313px) and (max-width: 1504px) {
    display: none !important;
  }
}

