.svg-rotate {
    transform-origin: center;
    animation: rotate 60s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Example usage */
.rotate-on-hover:hover .svg-rotate {
    animation-play-state: running;
}

.rotate-on-hover .svg-rotate {
    animation-play-state: paused;
}