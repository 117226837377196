.top-bg {
  background-image: url("../../../../assets/images/GlobeAsset.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.landing-bg {
  background-image: url("../../../../assets/images/GlobeAsset.jpg");
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.text-content {
  h2 {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
  }

  h6 {
    color: #fff;
    font-size: 12px;
    font-weight: 200;
  }

  h3 {
    color: #fff;
    font-size: 27px;
  }

  margin-top: -20px;
  text-align: center;
  margin-bottom: -50px;
}

.icon-in-polygon {
  position: absolute;
  top: 6vw;
  color: #fff;
  margin-left: -9.5vw;
  width: 3vw;
  height: 3vw;
  vertical-align: middle;
}

.polygon-lable-mid {
  position: absolute;
  font-size: 1vw;
  top: 10vw;
  color: #fff;
  width: 28%;
  margin-left: 11.2vw;
}

.polygon-lable-mid-2 {
  position: absolute;
  font-size: 1vw;
  top: 10vw;
  color: #fff;
  width: 35%;
  margin-left: 10vw;
}

.polygon-lable-btm {
  position: absolute;
  font-size: 1.6vw;
  // font-size:2vw;
  font-weight: 500;
  // top: 14vw;
  top: 13vw;
  color: #fff;
  width: 25%;
  margin-left: 11.7vw;
  // margin-left:36%;
}

// @media screen and (max-width: 600px) {

//   .polygon-lable-mid{
//     position: absolute;
//     font-size: 1vw;
//     top: 10vw;
//     color:#fff;
//     width:28%;
//     margin-left: 14vw;
//   }

//   .polygon-lable-mid-2{
//     position: absolute;
//     font-size: 1vw;
//     top: 10vw;
//     color:#fff;
//     width:35%;
//     margin-left: 12.5vw;
//   }

//   .polygon-lable-btm{
//     position: absolute;
//     font-size:1.6vw;
//     font-weight: 500;
//     top: 13vw;
//     color:#fff;
//     width:25%;
//     margin-left: 14.5vw;
//   }

//   .middle-bar-1{
//     position: absolute;
//     opacity:0;
//     margin-top: -5px;
//     margin-left: 12vw;
//   }

//   .middle-bar-3{
//     position: absolute;
//     opacity:0;
//     margin-left: 63vw;
//     margin-top: -5px;
//   }

//   .middle-bar-image-3{
//     position: absolute;
//     width:108vw;
//     height:5vw;
//     margin-left: 8vw;
//   }
// }

.image-content {
  position: relative;
  margin-top: -5px;
}

.middle-bar-image-1 {
  position: absolute;
  width: 78vw;
  height: 5vw;
  margin-left: 8vw;
}

.trangle {
  position: absolute;
  height: 1vw;
  width: 1vw;
  margin-left: -1vw;
  margin-top: 4%;
  opacity: 0;
  z-index: 1000;
}

.middle-bar-image-2 {
  position: absolute;
  width: 78vw;
  height: 5vw;
  margin-left: 8vw;
}

.middle-bar-image-3 {
  position: absolute;
  width: 78vw;
  height: 5vw;
  margin-left: 8vw;
}

.middle-bar-1 {
  position: absolute;
  opacity: 0;
  margin-top: -5px;
}

.middle-bar-2 {
  position: absolute;
  opacity: 0;
  margin-left: -32vw;
  margin-top: -5px;
}

.middle-bar-3 {
  position: absolute;
  opacity: 0;
  margin-left: -63vw;
  margin-top: -5px;
}

.middlebar-content {
  display: flex;
  position: absolute;
  margin-left: 7vw;
  margin-top: 1vw;
}

.middlebar-text {
  display: flex;
  margin-left: 5%;
  // margin-top:-4%;
}

.column-1 {
  width: 33%;
}

.column-2 {
  width: 33%;
}

.column-3 {
  width: 33%;
}

.column-1:hover .middle-bar-1 {
  opacity: 1;
}

.column-1:hover .trangle {
  opacity: 1;
}

.column-2:hover .middle-bar-2 {
  opacity: 1;
}

.column-2:hover .trangle {
  opacity: 1;
}

.column-3:hover .middle-bar-3 {
  opacity: 1;
}

.column-3:hover .trangle {
  opacity: 1;
}

.tab {
  color: #fff;
  font-size: 0.8vw;
}

.tab span {
  color: #fff;
  font-size: 1.2vw;
}

.middlebar-icon {
  color: #fff;
  font-size: 1.5vw;
}

.tab-container {
  margin-bottom: 5%;
}

.hexagon-image-col-1 {
  opacity: 0.7;
  width: 15.5vw;
  height: 15.5vw;
  margin-top: 9%;
}

.hexagon-image-col-2 {
  opacity: 0.7;
  width: 15.5vw;
  height: 15.5vw;
  margin-top: 9%;
}

.hexagon-image-col-3 {
  opacity: 0.7;
  width: 15.5vw;
  height: 15.5vw;
  margin-top: 9%;
}

.wrapper {
  display: flex;
  text-align: center;
}

.filter-btn {
  border: 2px solid #588baa;
  border-radius: 22px;
  color: #588baa;
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 8px 8px 8px 0;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: #ffffff;
  height: 40px;
}

.filter-btn:hover {
  color: rgb(245, 241, 241);
  background-color: #296f9e;
}

.modal-popscreen {
  margin-top: 12%;
  width: 35%;
  height: 50%;
  margin: auto;
}

.lable-customer {
  width: 100%;
  margin-top: 10px;
}

.select-input1 {
  margin-top: 10px;
  z-index: 9;
}

.select-input2 {
  margin-top: 10px;
  z-index: 8;
}

.select-input3 {
  margin-top: 10px;
  z-index: 7;
}

.select-input4 {
  margin-top: 10px;
  z-index: 6;
}

.select-input5 {
  margin-top: 10px;
  z-index: 5;
}

.select-input6 {
  margin-top: 10px;
  z-index: 4;
}

.dash {
  margin-top: 10px;
}

.home-nav-tab {
  background-color: #fff;
  padding: 0% 4%;
  position: sticky;
  top: 300;
}

.home-nav-tab-contaner {
  background-color: #fff;
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.tab-icon-font {
  font-size: 20px;
}

.tab-text-font {
  font-size: 12px;
}

@media screen and (max-width: 770px) {
  .filter-btn {
    height: 21px;

    border: 2px solid #588baa;
    border-radius: 22px;
    color: #588baa;
    padding: 1px 5px;
    font-size: 10px;
    margin: 5px 8px 8px 0;
    background-color: #ffffff;
  }
  .filter-icon {
    font-size: 8px;
  }
}

@media screen and (max-width: 990px) {
  .modal-popscreen {
    margin-top: 5%;
    width: 85%;
    height: 50%;
  }
}

@media screen and (max-width: 1480px) {
  .tab-text-font {
    font-size: 11px;
  }
  .tab-icon-font {
    font-size: 17px;
  }
}

.home-content {
  margin-right: 0px;
  background-color: #ffffff;
}

// @media screen and (max-width: 600px) {
//   .home-content{
//     margin-right: -100px;

//   }
// }
.comparison_grid_button:hover{
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.analytics-button:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.comparison_grid_button3:hover{
  transform: scale(1.02);
  transition: transform 0.3s ease;
}
// .comparison_warning_icon{
//   width: 86px !important;
//   height: 86px !important;
//   border-width: 5px !important;
//   .swal2-icon-content{
//     font-size: 60px !important;
//   }
// }
// .comparison_warning_popup{
//   width: 540px !important;
//   height: 153px !important;
//   border-radius: 10px !important;
//   padding-top: 20px;
//   padding-bottom: 20px; 
// }
// .comparison_warning_title{
//   font-size: 16px !important;
//   font-weight: 500 !important;
//   color: #495057 !important;
// }
.floating-button {
  position: fixed;
  // bottom: 20px;
  width: 141px;
  height: 25px;
  top: 50vh;
  right: 0px;
  background-color: #FFFFFF;
  color: #015F81;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  z-index: 1000;
  cursor: pointer;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  text-align: center;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 3px 5px 0px #00000040;
  :hover{
      transform: scale(1.02);
      transition: transform 0.3s ease;
  }
  @media screen and (max-width: 770px) {
    top: 55vh;
  }
}
@media screen and (max-width: 770px) {
  .floating-button {
    top: 43vh;
  }
}

.list-item-container {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 1rem;
}

.list-item {
  display: flex;
  flex-wrap: nowrap;
}
.overFlow_compare{
  min-width: 1400px;
}
.mapContainer{
  @media screen and (max-width: 919px) {
    padding-bottom: 20%;
  }
}
.comparison_container{
  @media screen and (max-width: 919px) {
    padding-bottom: 20% !important;
  }
}