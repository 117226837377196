.aboutus-bg{
    background-image: url("../../../../assets/images/About-US.png");
    background-size:     cover;                    
    background-repeat:   no-repeat;
    background-position: center center;  
    width: 100%;
    height: 30%;
    background-attachment: fixed;
    position: relative;
    padding-top: 10px;
}


.aboutus-textcontent{
    h2{ color:#fff;font-size: 33px;}
    h6{ color:#fff;font-size: 16px;}
    text-align: center;     
}

.abt-content{
    width: 100%;
    padding-left: 5%;
    padding-right:5%;
    padding-top: 0.75%;
    @media screen and (max-width: 581px) {
        padding-bottom: 10%;
        
    }

}

.abt-card{
    border-radius: 5px;
    box-shadow: rgba(49, 49, 49, 0.15) 0px 5px 15px 0px;
    text-align: justify;
    padding-right: 4%;
    padding-left: 4%;
    padding-top:2%;
    padding-bottom: 2%;
}

.abt-icon{
    padding-top: 6%;
}

.icon-content{
    padding-top: 6%;

}

.abt-main{
    text-align: justify;
}

.key-highligts{
    margin-left: 5%;
    i{
        color: #556ee6;
    }
}

.quick-link{
    background-color: #f3f3f9;
}

.readmore-btn{
    color: #556ee6;
}

.first-popscreen {
    margin-top: 5%;
    width: 90%;
    height: 80%;
    // margin-top: 5%;
  margin-top: 12%;
//   width:35%;
//   height:50%;
  margin: auto;

}


.second-popscreen {
    margin-top: 5%;
    width: 90%;
    height: 80%;
     // margin-top: 5%;
  margin-top: 12%;
  //   width:35%;
  //   height:50%;
    margin: auto;

}

.third-popscreen {
    margin-top: 5%;
    width: 90%;
    height: 80%;
     // margin-top: 5%;
  margin-top: 12%;
  //   width:35%;
  //   height:50%;
    margin: auto;

}

.modal-content{
    text-align: justify;
}
@media screen and (max-width: 1920px) {
    .modal-content {
        margin-top: 0%;
    }
}

@media screen and (max-width: 768px) {
    .modal-content {
        margin-top: 5%;
    }
}

@media screen and (max-width: 575px) {
    .modal-content {
        margin-top: 10%;
    }
}

.source-link{
    word-wrap: break-word;
}