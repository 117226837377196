.disclaimer-container{
    text-align: justify;
    margin-left: 1.8%;
    margin-right: 1.8%;
    padding: 0.2% 6% 0.5% 6%;
    margin-bottom: 10%;
    @media screen and (max-width: 581px) {
        margin-bottom: 25%;
    }
}

.disclaimer-card{
    margin-left: 1%;
    margin-right: 4%;
    padding: 2% 0% 0.5% 3%;
}
.disc-h{
    font-weight: 700;
}

@media (max-width: 500px) {
    
    .disclaimer-card{
        padding: 8% 0% 0.5% 3%;
    }

    .disclaimer-container{
        padding: 10% 6% 0.5% 6%;
    }

    .header{
        text-align: left;
    }

    .omi-disc{
        text-align: left;
    }

    .carbon-link{
        text-align: left;
        word-wrap: break-word;
    }
}

