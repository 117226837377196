/* Mobile (up to 575px) */
@media (max-width: 575.98px) {
  .credits-issued {
    font-size: 24px !important;
  }
}

/* Tablet (576px to 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .credits-issued {
    font-size: 32px !important;
  }
}

/* Small Desktop (768px to 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .credits-issued {
    font-size: 32px;
  }
}

/* Medium Desktop (992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .credits-issued {
    font-size: 52px;
  }
}

/* Large Desktop (1200px to 1399px) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .credits-issued {
    font-size: 52px;
  }
}

/* Extra Large Desktop (1400px and up) */
@media (min-width: 1400px) {
  .credits-issued {
    font-size: 52px;
  }
}

/* Base font size */
.credits-retired-fonts {
  font-size: 68px;
  color: #8dd98e;
}

/* Font size for small screens (e.g., phones) */
@media (max-width: 600px) {
  .credits-retired-fonts {
    font-size: 48px;
  }
}

/* Font size for medium screens (e.g., tablets) */
@media (max-width: 900px) and (min-width: 601px) {
  .credits-retired-fonts {
    font-size: 68px;
  }
}

/* Font size for large screens (e.g., desktops) */
@media (min-width: 901px) {
  .credits-retired-fonts {
     font-size: 68px;
  }
}
