.disclaimer-container{
    text-align: justify;
    margin-left: 1.8%;
    margin-right: 1.8%;
    padding: 0.2% 6% 0.5% 6%;
}
.policy-subtopic{
        p{
            margin: 20px 10px 30px 50px;
        }

        i{
            color: #556ee6;
        }
    }


.paragraph-bold{
    font-weight: 500;
    margin:20px 10px 30px 50px;
    i{
        color: #556ee6;
    }
}

.include-paragraph{
    margin:0px 10px 0px 50px;
    i{
        color: #556ee6;
    }
}

.include-points{
    margin:0px 10px 0px 50px;
    i{
        color: #556ee6;
    }
}

.policy-card{
    margin-left: 1%;
    margin-right: 4%;
    padding: 2% 0% 0.5% 3%;
}

.policy-h{
    font-weight: 600;
}

@media (max-width: 500px) {
    .policy-card{
        padding: 8% 0% 0.5% 3%;
    }

    .include-paragraph{
        margin:0px 10px 0px 20px;
        // text-align: justify;
    }

    .paragraph-bold{
        margin:20px 10px 30px 20px;
    }

    .policy-subtopic{
        p{
            margin: 20px 10px 30px 20px;
        }
    }
}