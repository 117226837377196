.card-continer {
  // overflow-y: scroll;
  // height: 600px;
  min-width: 450px;
  align-items: left;
  justify-content: left;
}

.card-inside-col {
  display: grid;
  // grid-auto-flow: column;
  // gap: 4px;
  align-items: center;
  justify-items: center;
}

.grid-card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  margin: 15px 1px 15px 1px;
  max-width: 420px;
  min-width: 350px;
  // width:320px;
  width: 100%;
  // width:270px;
}

.grid-crad-img {
  width: 100%;
  height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.grid-crad-img-dft {
  width: 100%;
  height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.grid-cardbody {
  padding: 1px;
  border: 5px 5px 5px 5px;
  // min-height: 310px;
  // width:320px;
  // width:270px;
  width: 100%;
  height: 100%;
  max-width: 420px;
  min-width: 275px;
  // max-width: 380px;
  // min-width: 280px;
}

.grid-cardbody:hover {
  background-color: rgb(223, 248, 248);
  border-radius: 10px;
}

.grid-card-empty {
  border-radius: 10px;
  margin: 15px 0px 15px 0px;
  max-width: 420px;
  min-width: 275px;
  // width:320px;
  width: 100%;
  // width:270px;
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1200px) {
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .grid-card {
    //   width:320px;
    width: 100%;
    //   margin: 15px 15px 15px 15px;
  }
}

@media screen and (max-width: 600px) {
  .grid-cardbody {
    //   width:320px;
    width: 100%;
  }
}

// @media (max-width: 1440px) {
//     .grid-card{
//         width:290px;
//     }
//     .grid-cardbody{
//         width:290px;
//     }
//   }

.page-content-1 {
  margin-left: 5%;
  width: 90%;
  margin-right: auto;
  // margin-left: -40px ;
  // margin-right: -60px ;
  // margin: 0px 10px 0px 10px;
}

.gcard-main-title {
  margin-top: 15px;
  margin-bottom: 1px;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  color: black;
}
.dev_leb {
  background-color: #57c89c;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  padding: 3px 15px;
  margin-top: 5px;
}

.plain_leb {
  color: #495057;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 0px 0px 8px;
  margin-top: 5px;
}
.gcard-crd-date {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: black;
}

.gcard-credit-avbl {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.gcard-sub-title {
  font-family: "Poppins";
  font-size: 10px;
  color: black;
}

.gcontent {
  font-family: "Poppins";
  font-size: 13px;
  color: black;
}

/* Style the flag container and hide the text by default */
.flag-container {
  position: relative;
  display: inline-block;
}

.flag-hover-text {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff; /* Background color for the tooltip */
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: none; /* Hide the text by default */
}

/* Show the text when hovering over the flag container */
.flag-container:hover .flag-hover-text {
  display: inline-block;
}

.hover-effect:hover {
  /* Define the styles you want to apply on hover here */
  background-color: lightblue;
  transform: scale(1.1);
}

.hovered-card:hover {
    box-shadow: rgba(70, 156, 226, 0.25) 0px 14px 28px, rgba(70, 156, 226, 0.25) 0px 10px 10px !important;
}


@media screen and (max-width: 1470px){
  .con-card{
    padding-top: 50px;
  }
}

@media screen and (max-width: 2561px){
  .con-card{
    padding-top: 150px;
  }
}

@media screen and (max-width: 1921px){
  .con-card{
    padding-top: 100px;
  }
}

@media screen and (max-width: 1821px){
  .con-card{
    padding-top: 10px;
  }
}

@media screen and (max-width: 769px){
  .con-card{
    padding-top: 20px;
  }
}

@media screen and (max-width: 430px){
  .con-card{
    padding-top: 40px;
  }
}




 