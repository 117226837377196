.view-btn-tbl{
    color:#57C89C;
    // background-color:#FFFFFF;
    border:none;
}   
.view-btn-tbl:hover{
    color:#196e2c;
    font-weight: 500;
    // background-color:#FFFFFF;
    border:none;
} 
.content-container{
    margin-top: 5%;
}
.prj_tit_leb{
    font-size: 20px;
}

.prj_location_leb{
    font-size: 13px;
    font-weight: 400;
}
.cspid_leb{
    font-size: 13px;
    font-weight: 600;
}
.content-align-right{
    float: right;
    text-align: right;
}
.blue-border-btn{
  
  border: none;
  background-color: white; 
  color: black; 
  border: 2px solid #2679d8;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 8px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius:15px;
}
.blue-border-btn:hover{
    background-color: #2679d8;
    color: white;
}

.close-btn_view{
    border: none;
    background-color: #ce4747; 
    color: rgb(240, 233, 233); 
    border: 2px solid rgb(255, 255, 255);
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 1px 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius:17px;
}

.close-btn_view:hover{
    background-color: #e42e2e;
    color: rgb(15, 14, 14);
}
.green_long_btn{
    display: inline-block;
    border-radius: 20px;
    background-color: #90BE83;
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 5px 15px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 8px 2px;
    width: 100%;
}
.green_long_btn:hover{
    border: none;
    background-color: 2px solid #2cce6f;
    color: black; 
    
}
.input-dtl-leb{
    font-size: 13px;
    font-weight: 400;
}
.output-dtl-leb{
    font-size: 13px;
    font-weight: 500;
    word-wrap: break-word;
    text-align: justify;
    
}

.source-output-dtl-leb{
    font-size: 13px;
    font-weight: 500;
    word-wrap: break-word; 
    width:100%;  
}

.doc-name-lable{
    font-size: 13px;
    font-weight: 500;
    word-wrap: break-word;
    width: 100%;
}

.crediting-output-leb{
    font-size: 13px;
    padding-left: 20px;
    font-weight: 600;
}

.documents{
    text-align:center;
    height: 100%;
    
    
}
.document-col{
    margin: 20px 0 20px 0;
    max-width: 450px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
  
.flex_row{
justify-content: center;
display: flex;
padding-top: 20px;
padding-bottom: 0px;
}

// .flex_row{
//     justify-content: center;
//     display: flex;
//     padding-top: 20px;
//     padding-bottom: 0px;
//     margin-left: 18%;
//     max-width: 500px;
//     overflow-x: scroll;
//     padding-left: 400px;
//   }

.thumbnail{
padding-left: 20px;
}

#textalign{
text-align: justify;
}
.title-div{
    height: 28%;
}
.width{
width:27%;
padding-left: 1.5%;
}
.document-container{
    padding-top: 20px;
    padding-bottom: 10px;
}
.document-pdf-icon{
    height:60px;
    width:50px;
    margin-bottom: 10px;
}

.document-pdf-icon2{
    height:25%;
    width:25%;
    margin-bottom: 10px;
    opacity: 0.3;
}
.sdg-icon{
    cursor: pointer;
   width: 30px; 
   height: 30px;
   margin-left: 5px;
   margin-bottom: 5px;
   transition: all 0.3s ease-in-out;
}

.sdg-icon-large {
    width: 75px;
    height: 75px;
    // border: 2px solid rgb(2, 2, 2);
  }

.sdg-container{
    display: flex; 
    justify-content: right;
    float: right
}
.sdg-icon-row{
    float: right;
    padding-left:10px;
}

.sdg-details-row {
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.sdg-details {
    // background-color:#90BE83;
    padding: 10px;
    border-radius: 5px;
}

.yesno-icon{
    width: 15px; 
   height: 15px;
}

.slideshow-content{
    text-align: center;
    margin-top:30px;
    // margin-bottom: -5%;
   
  }
.preview-img{
    width: 99% ;
    height: 25vw;
    object-fit: contain;
    // object-fit: cover;
   
}

.esti_chart{
    margin-left: 0px;
    overflow: Scroll;
}

.emptydata-body{
    font-weight: 300;
    color:#be7195;
}

.box-legend{
    
    height: 15px;
    width: 15px;
}


#actual-color{
    background-color: #556ee6;
}

#estimated-color{
    background-color: #34c38f;
}

.bar-chart{
    padding-top: 4%;
    padding-left: 25%;
    padding-right: 10%;
}

.thumbnail-container-1 {
        display: flex;
        overflow-x: scroll;
        margin-top: 10px;
        padding: 5px;
        max-width: 100%;
        // margin-left:24%;
        // justify-content: center;
        
}

//   .thumbnail-1:not(:first-child) {
//     .thumbnail-container-1{
//     display: flex;
//     overflow-x: scroll;
//     margin-top: 10px;
//     padding: 5px;
//     max-width: 100%;
//     justify-content:flex-start;
//     }
//   }

  .thumbnail-1{
    margin-right: 2%;
  }

  .scroll4::-webkit-scrollbar {
    width: 10px;
  }
   
  .scroll4::-webkit-scrollbar-thumb {
    background: #90B2C7;
    border-radius: 20px;
  }
  
  .scroll4::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

//   .thumbnail-container-1 {
//     display: flex;
//     overflow-x: scroll;
//     justify-content: center;
//     align-items: center;
//     margin-top: 10px;
//     padding: 5px;
//     max-width: 100%;
//     min-width: 300px; /* set a minimum width */
//   }
  
//   .thumbnail-1 {
//     flex-shrink: 0;
//     margin-right: 5px;
//   }
  
//   .thumbnail-1:last-child {
//     margin-right: 0;
//   }
  
//   .thumbnail-1 img {
//     cursor: pointer;
//     border-radius: 10px;
//   }
  
  
.icon-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 15px;
  }

  .icon-container {
    transition: transform 0.3s ease;
  }
  .icon-container:hover {
    transform: scale(1.3);
  }