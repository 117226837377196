.nav-tabs-custom .nav-item .nav-link.active{
    color:#588BAA
}

.nav-link:focus, .nav-link:hover{
    color:#588BAA
}

.nav-tabs-custom .nav-item .nav-link::after{
    background:#588BAA
}

.mobile-select-container {
    border: 1px solid #EEE4E4
}

.tbl-body-con{
    max-height: 200px !important;
}