.topping{
    font-weight: 550;
}

.register-btn{
    width: 100%;
    height:45px;
    display: inline-block;
    background-color: #90BE83;
    border: none;
    transition: all 0.5s;
    margin: 5px;
    color: #fff;
    font-size: 14px;
    border-radius: 0.75em;    
  }