// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.table-card-body{
  padding-top: 0%;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
  
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}
.card-design{
  padding: 0 15px;
}
#sec-scope{
  margin-top: -5%;
  font-weight: 500;
  font-size: 12px;
}
#prop-locate{
  margin-top: -4%;
}

.bottemofcard{
  .my-3{
    margin-bottom: 6px !important;
  }
}

.registry-label {
  font-size: 10px;
  color: #959595;
  line-height: 10px;
  max-height: 10px;
  overflow: clip;
  font-weight: 600;
  width: 100%; 
}

.registry-label1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
