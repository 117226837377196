@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.chat-box-sticky .markdown>* {
  all: revert;
  text-align: left;
}

.chat-box-sticky p,
.chat-box-sticky a {
  word-wrap: break-word;
}

.chat-box-sticky a {
  color: #1d4ed8;
  text-decoration: underline;
}

/* carbonespace ai main gradient */
.chat-box-sticky .csai-common-grad {
  background-image: linear-gradient(to right, #77AFC7, #60AA94);
}

/* ai avtar gradient */
.chat-box-sticky .csai-agent {
  background-image: linear-gradient(to left, #60AA94, #77AFC7);
}

/* user avtar gradient */
.chat-box-sticky .csai-user {
  background-image: linear-gradient(to right, #77AFC7, #60AA94);
}

.chat-box-sticky .csai-common-text {
  color: #9CA3AFFF;
}

.chat-box-sticky label {
  color: #9CA3AF;
  margin-left: 12px;
  margin-bottom: 4px;
}

.chat-box-sticky .no-scrolling {
  overflow: hidden;
  resize: none;
}

.chat-box-sticky .userMessage p {
  text-align: end;
}

.chat-box-sticky .assistantMessage p {
  text-align: start;
}

.chat-box-sticky .error {
  bottom: -80%;
}

.chat-box-sticky .show-error {
  bottom: -70%;
}

/* width */
.chat-box-sticky ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.chat-box-sticky ::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.chat-box-sticky ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.chat-box-sticky ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-box-sticky {
  @apply fixed bottom-20 right-4;
}

@media (max-width: 640px) {
  .chat-box-sticky {
    @apply bottom-20 right-6 left-3; /* Adjust width and height for smaller screens */
  }
}

.chat-box-card {
  @apply bg-neutral-800 rounded-lg shadow-xl flex flex-col w-full h-[70vh] overflow-y-auto sm:w-[28rem] sm:h-[28rem] md:w-[28rem] md:h-[32rem] lg:w-[28rem] lg:h-[36rem];
}

.chat-box-header {
  @apply flex justify-between items-center p-4 text-white rounded-t-lg;
}

.chat-box-title {
  @apply w-fit text-2xl font-bold;
}

.chat-box-title-gradient {
  @apply inline-block text-transparent bg-clip-text csai-common-grad;
}

/* 
.chat-box-close {
  @apply csai-common-text text-2xl;
} */
/* chat-box-close remove background color */
.chat-box-close {
  @apply csai-common-text text-2xl bg-transparent;
}

.chat-box-content {
  @apply flex-grow p-4 overflow-auto;
}

.chat-box-messages {
  @apply w-full h-full;
}

.chat-box-recent-chats {
  @apply w-fit h-fit;
}

.chat-box-recent-header {
  @apply text-white ml-4 mr-4 mt-2 mb-2;
}

.chat-box-recent-title {
  @apply w-fit font-semibold;
}

.chat-box-recent-list {
  @apply w-full pl-4 pr-4;
}

.chat-box-chat-button {
  @apply w-full mb-3 p-2 rounded-xl bg-slate-600 transition-all duration-150 delay-75 hover:bg-slate-700;
}

.chat-box-chat-active {
  @apply border-2 border-[#60AA94] border-opacity-50;
}

.chat-box-chat-content {
  @apply flex flex-row items-center w-full h-fit csai-common-text;
}

.chat-box-history-icon {
  @apply w-2/12 p-1 mr-2 text-white;
}

.chat-box-chat-title {
  @apply w-10/12 pb-2 mt-2 text-left text-white;
}

.chat-box-footer {
  @apply p-4 border-t border-neutral-700;
}

.chat-box-buttons {
  @apply flex mb-1;
}

.chat-box-refresh-btn {
  @apply ml-2 py-2 px-2 rounded-xl hover:transition-all duration-300 ease-in-out focus:outline-none bg-[#60AA94] hover:bg-[#13536C] dark:hover:bg-neutral-800;
}

.chat-box-refresh-content {
  @apply flex flex-row items-center text-white;
}

.chat-box-refresh-icon {
  @apply h-fit w-fit text-white;
}

.chat-box-refresh-text-content {
  @apply text-white;
}

.chat-box-refresh-text {
  @apply csai-common-text ml-2 hover:transition-all delay-150 duration-300 ease-in-out;
}

.chat-box-refresh-visible {
  @apply w-full;
}

.chat-box-refresh-hidden {
  @apply w-0 hidden;
}

.chat-box-tool-btn {
  @apply ml-2 py-2 px-2 w-20 rounded-xl focus:outline-none bg-neutral-800;
}

.chat-box-tool-content {
  @apply flex flex-row;
}

.chat-box-tool-icon-container {
  @apply csai-common-text ml-0 mr-0 w-0 hover:transition-all duration-300 ease-in-out group-hover:w-6 group-hover:ml-1 group-hover:mr-1;
}

.chat-box-tool-icon-active {
  @apply text-white;
}

.chat-box-tool-icon-inactive {
  @apply text-white;
}

.chat-box-tool-dropdown {
  @apply flex csai-common-text bg-transparent mr-2 group-hover:w-fit group-hover:bg-slate-700;
}

.chat-box-tool-dropdown-icon {
  @apply relative top-1 left-6 ml-1 text-white;
}

.chat-box-input-container {
  @apply w-full max-w-5xl mx-auto;
}

.chat-box-input-form {
  @apply flex items-center w-full py-2 bg-gray-900 rounded-md shadow-sm focus-within:ring-neutral-500 focus-within:ring-1;
}

.chat-box-textarea {
  @apply flex w-full no-scrolling resize-none rounded-md bg-transparent py-2 px-3 placeholder:text-sm placeholder:text-neutral-400 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 text-slate-50 border-none;

}

.chat-box-send-icon {
  @apply mr-4 text-neutral-600 peer-focus:text-neutral-500 text-white peer-focus:text-neutral-300 bg-gray-900;
}

.chat-box-toggle-btn {
  @apply fixed bottom-24 right-8 bg-white text-white rounded-full shadow-lg p-0 w-20 h-20 hover:shadow-xl hover:scale-110 transition-all ease-in-out duration-100;
}

@media screen and (max-width: 640px) {
  .chat-box-toggle-btn {
    @apply bottom-24 right-4 w-16 h-16;
  }
}

.chat-box-toggle-btn::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
  background: #60AA9455;
  animation: pulse 2s infinite;
  z-index: -1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

.chat-agent-setting-container {
  @apply absolute z-30 bottom-[12.5rem] left-[1rem] sm:left-[2rem];
}

.chat-agent-setting-window {
  @apply w-72 bg-neutral-800 rounded-lg shadow-md mt-1 transition-all duration-100 ease-in-out opacity-100 translate-y-0;
}

.chat-agent-setting-list {
  @apply list-none p-2;
}

.chat-agent-setting-item {
  @apply mb-1;
}

.chat-agent-setting-button {
  @apply w-full px-4 py-2 rounded-md text-left hover:bg-gray-100 hover:bg-slate-500 bg-neutral-700;
}

.chat-agent-setting-content {
  @apply flex text-white bg-neutral-700;
}

.chat-agent-setting-icon {
  @apply flex w-1/6 items-center;
}

.chat-agent-setting-details {
  @apply w-4/6;
}

.chat-agent-setting-description {
  @apply mt-1 mb-1 text-xs;
}

.chat-agent-setting-warning {
  @apply mt-1 mb-1 text-xs text-orange-400;
}

.chat-agent-setting-check {
  @apply grid place-content-center w-1/6;
}

.chat-box-loading-container {
  @apply flex items-center justify-center w-full h-full;
}

.chat-box-loading-icon {
  @apply w-14 h-14 csai-common-text animate-bounce;
}

.chat-box-message-container {
  @apply flex pb-8 last:pb-4 last:sm:pb-16 ;
}

.chat-box-message-row-assistant {
  @apply flex-row pb-8 last:pb-4 last:sm:pb-16;
}

.chat-box-message-row-user {
  @apply flex-row-reverse pb-8 last:pb-4 last:sm:pb-16 first:sm:pt-8;
}

.chat-box-message-avatar {
  @apply grid content-start w-1/6;
}

.chat-box-message-avatar-icon {
  @apply p-1 w-12 h-12 relative top-4 left-4 rounded-full;
}

.chat-box-message-avatar-assistant {
  @apply bg-[url('../../assets/images/csai.svg')] bg-contain bg-center;
}

.chat-box-message-avatar-user {
  @apply csai-user;
}

.chat-box-message-content {
  @apply flex w-5/6 max-w-3xl gap-4 py-0 mx-auto;
}

.chat-box-message-content-assistant {
  @apply flex-row sm:pr-8;
}

.chat-box-message-content-user {
  @apply flex-row-reverse sm:pl-8;
}

.chat-box-message-text {
  @apply w-fit p-4;
}

.chat-box-message-text-assistant {
  @apply w-full;
}

.chat-box-message-text-user {
  @apply bg-neutral-100 bg-slate-600 rounded-3xl drop-shadow-sm;
}

.chat-box-message-markdown {
  @apply overflow-x-hidden;
}

.chat-box-message-markdown-assistant {
  @apply markdown assistantMessage text-white;
}

.chat-box-message-markdown-user {
  @apply userMessage text-white bg-slate-600;
}

.chat-box-thinking-container {
  @apply flex flex-row pb-8 last:pb-4 last:sm:pb-16 first:sm:pt-8;
}

.chat-box-thinking-avatar-container {
  @apply grid content-start w-1/6;
}

.chat-box-thinking-avatar {
  @apply p-2 w-12 h-12 relative top-4 left-4 rounded-full csai-agent;
}

.chat-box-thinking-message-container {
  @apply flex flex-row items-center mt-4 sm:pr-8 w-5/6 max-w-3xl gap-4 py-0 mx-auto;
}

.chat-box-thinking-message-text {
  @apply text-sm csai-common-text italic pl-0 transition-opacity duration-500;
}

.chat-box-thinking-message-visible {
  @apply opacity-100;
}

.chat-box-thinking-message-hidden {
  @apply opacity-0;
}
.chat-box-token-info{
  @apply flex flex-row items-center justify-between;
}
.chat-box-token-info-text{
  @apply text-gray-400 text-xs
}