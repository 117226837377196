// 
// preloader.scss
//

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $card-bg;
    z-index: 9999;
}

#status {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.spinner-chase {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    position: relative;
    animation: spinner-chase 2.5s infinite linear both;
}

.chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: chase-dot 2.0s infinite ease-in-out both; 
    &:before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: $primary;
        border-radius: 100%;
        animation: chase-dot-before 2.0s infinite ease-in-out both; 
    }

    &:nth-child(1) { 
        animation-delay: -1.1s; 
        &:before{
            animation-delay: -1.1s;
        }
    }
    &:nth-child(2) { 
        animation-delay: -1.0s;
        &:before{
            animation-delay: -1.0s;
        }
    }
    &:nth-child(3) { 
        animation-delay: -0.9s;
        &:before{
            animation-delay: -0.9s;
        } 
    }
    &:nth-child(4) { 
        animation-delay: -0.8s; 
        &:before{
            animation-delay: -0.8s;
        } 
    }
    &:nth-child(5) { 
        animation-delay: -0.7s; 
        &:before{
            animation-delay: -0.7s;
        } 
    }
    &:nth-child(6) { 
        animation-delay: -0.6s; 
        &:before{
            animation-delay: -0.6s;
        }
    }
}

@keyframes spinner-chase {
    100% { 
        transform: rotate(360deg); 
    } 
}

@keyframes chase-dot {
    80%, 100% { 
        transform: rotate(360deg); 
    } 
}

@keyframes chase-dot-before {
    50% {
        transform: scale(0.4); 
    } 
    100%, 0% {
        transform: scale(1.0); 
    } 
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 65px;
    height: 65px;
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3f6a83; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .loader-text{
    font-size: 25px;
    text-align: center;
  }
  .form-input{
    width: 100%;
    height: 39px;
    margin: 12px 10px 15px 0px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 4px;
}
.select-input{
    width: 100%;
    margin-top: 13px;
    margin-bottom:12px ;
}
.select-input-half{
    width: 100%;
    margin-top: 6px;
}

.form-input-half{
    width: 47%;
    margin: 5px 10px 15px 0px ;
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 4px;
}

.form-sub-title{
    margin-top: 20px;
    margin-bottom: 10px;
    color:#101827;
}
.form-sub-title2{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
}

.form-sub-title-lable{
    font-size: 15px;
    font-weight: 10;
    margin-top: -10px;
}

.radio-input{
    padding-top: 15px;
    font-size: 16px;

}
textarea {
     width: 100%;
     margin: 5px 10px 15px 0px ;
     padding: 8px;
     box-sizing: border-box;
     border: 2px solid #ccc;
     border-radius: 4px;
     font-size: 16px;
     resize: none;
 }

.threerows{
    height: 87%;
}

.tworows{
    height: 73%;
}

.add-btn {
    background-color: #2d67d3; /* Green */
    height: 45px;
    color: white;
    border-radius: 8px;
    margin-top: 30px;
    padding: 10px 20px 10px 20px;
    border: 2px solid #dee5e6;
}
.add-btn:hover {
    transition-duration: 0.4s;
    background-color: #2A3042; /* Green */
    
}

.responce-btn {
    background-color: #2d67d3; /* Green */
    height: 45px;
    width: 100%;
    color: white;
    border-radius: 8px;
    margin-top: 30px;
    border: 2px solid #dee5e6;
}

.responce-btn:hover {
    transition-duration: 0.4s;
    background-color: #2A3042; 
    color: #d7e3e7;
    border: 2px solid #dee5e6;

}

.upload-btn {
    height: 48%;
    width: 100%;
    border-radius: 8px;
    margin-top: 32px;
    border: 2px solid #008CBA;
    color: #008CBA;
    font-size: 11px;
}

.upload-btn:hover {
    transition-duration: 0.4s;
    background-color: #2A3042; 
    color: #d7e3e7;
    border: 2px solid #dee5e6;
    
}

.remove-btn {
    height: 40px;
    width: 100%;
    border-radius: 8px;
    margin-top: 32px;
    border: 2px solid #008CBA;
    color: #008CBA;
    font-size: 11px;
}

.remove-btn:hover {
    transition-duration: 0.4s;
    background-color: #2A3042; 
    color: #d7e3e7;
    border: 2px solid #dee5e6;
    
}

.zero-margin{
    padding-right: -5px;
}
.image-box{
    width: 100%;
    margin-bottom: 20px;
}

.submit-btn-collection{
    float: right;
    margin-bottom: 15px;
}
.coordi-add-btn{
    float: right;
    margin-bottom: 15px;
    margin-right: 15%;
}

.credit-info-add-btn{
    float: right;
    margin-bottom: 15px;
}

#submit{
    background-color:rgb(92, 166, 92);
    margin-left: 10px;
}
#remove{
    background-color:rgb(216, 83, 83);
    height:44px ;
    color: #d7e3e7;
    border: 2px solid #cc3a77;
    
}
#remove:hover{
    transition-duration: 0.4s;
    border: 2px solid #dee5e6;
    background-color:rgb(230, 21, 21);
}
#delete{
    background-color:rgb(216, 83, 83);
    color: #d7e3e7;
    border: 2px solid #dee5e6;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    
}
#delete:hover{
    transition-duration: 0.4s;
    border: 2px solid #dee5e6;
    background-color:rgb(230, 21, 21);
}

#delete2{
    background-color:rgb(216, 83, 83);
    color: #d7e3e7;
    border: 2px solid #dee5e6;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    margin-top: 25px;
    margin-bottom: 12px;
    
}
#delete2:hover{
    transition-duration: 0.4s;
    border: 2px solid #dee5e6;
    background-color:rgb(230, 21, 21);
}

#removedis{
    background-color:rgb(224, 155, 155);
    color: #d7e3e7;
    border: 2px solid #dee5e6;
}


.remove1{
    background-color:rgb(216, 83, 83);
    color: #d7e3e7;
    border: 2px solid #dee5e6;
    height: 40px;
    width: 100%;
    border-radius: 8px;
    
}
.btn-add1{
    color: #fff;
    background-color: #008CBA;
    border-color: #008CBA;
    position:relative
}

.btn-add1:hover{
    color: #fff;
    background-color: #05607e;
    border-color: #05607e;
}
.btn-add1:focus{
    color: #fff;
    background-color: #008CBA;
    border-color: #008CBA;
}
.btn-check:checked + .btn-add1, .btn-check:active + .btn-add1, .btn-add1:active, .btn-add1.active, .show > .btn-add1.dropdown-toggle {
    color: #fff;
    background-color: #008CBA;
    border-color: #008CBA;
}



#submit:hover{
    background-color:green;
}
#img-btn{
    width: 90%;
    margin-left: 5%;
}
.content-margin{
    margin-left: 12px;
}
.content-margin2{
    margin-left: 1px;
}
.content-margin-remove{
    margin-left: -12px;
}

.button-margin{
    margin-top: -5px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.row-margin{
    margin-top: 10px;
    margin-bottom: 10px;
}

.toppings-list li {
    margin-bottom: 0.1rem;
  }

.toppings-list {
list-style: none;
padding: 0;
}

.toppings-list-item label {
margin-left: 0.5rem;
font-size: 18px;
}

.checkbox-container{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 4px;
    max-height: 125px;
    margin-top: 12px;
    margin-bottom: 20px;
    overflow-y: auto;
    padding-top: 0;

}

.new-box{
    display:flex;
    margin-top:8px ;
    width: 100%;
    
}

.progress-container {
    height: 150px;
    width: 150px;
  }
  .action-container {
    display: flex;
    gap: 15px;
  }
  .action-container button {
    font-size: 16px;
  }
  .spinner-container_table {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
    margin-top: -100px;
  }
  .loading-spinner_table{
    width: 65px;
    height: 65px;
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3f6a83; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  .loader-text-table{
    font-size: 25px;
    text-align: center;
    margin-top: -50px;
  }