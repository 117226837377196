
  
  .slider {
    position: relative;
    width: 89%;
    z-index: 0;
    // padding-left: 5%;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute; 

  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .slider__track {
    background-color: #ced4da;
    // width: 100%;
    width: 100%;
    z-index: 1;
    // padding-left: 5%;
  }
  
  .slider__range {
    background-color: #7E8CBC;
    z-index: 2;
    width: 100%;

  }
  
  .slider__left-value,
  .slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }
  
  .slider__left-value {
    left: 6px;
    
  }
  
  .slider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .range-input-box{
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    line-height: 0px;
    height:40px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .range-text{
    padding: 5px 0px 1px 5px;
    // font-size: 12px;
    // font-size: 0.8vw;
    font-size: 11px;
    color:#898b8d;
    // color:#949b9e;
    font-weight: 200;

  }

  .range-input{
    // font-size: 14px;
    font-size: 13px;
    // font-size: 1vw;
    color:#8B8F92;
    padding: 0px 0px 1px 5px;
    
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 79%;
    // padding-left: 5%;
    outline: none;
    // z-index: 1;
  }
  
  .thumb--left {
    z-index: 3;
  }
  
  .thumb--right {
    z-index: 4;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  .cre-available-box{
    display: inline-flex;
    width:90%;
  }
  

.credit-period{
  width:100%;
}
  
.container-ranger{
  width: 93%;
  padding-left: 3%;
}