// Icon nav
.icon-tab .nav-link{
  border: 1px solid #e9e9e9;
  color: #495057;
  
}
.icon-tab .active.nav-link{
  background: #556ee6;
  color: #fff;
}

.icon-tab .nav-link i,.vertical-icon .nav-link i{
  padding-right: 3px;
  font-size: 14px;
}
.vertical-icon .active.nav-link{
  border: 1px solid #556ee6;
  border-radius: 21px;
  background: #556ee6;
  color: #fff;
}
.vertical-icon .nav-link{
color: #495057;
}

// 
// _nav.scss
// 

.nav-tabs,.nav-pills {
  > li {
      > a {
          color: $gray-700;
          font-weight: $fw-medium;
      }
  }
}

.nav-pills {
  > a {
      color: $gray-700;  
      font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;
      // background-color: #FFFFFF;
      padding-left: 0 ;
      padding-right: 0;
      

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active{
        color: $primary;
        &:after{
          transform: scale(1);
        }
      }
      .tab-font{
        font-size: 12px;
      }

    }
  }
}

.nav-stick{
  position: sticky;
  top:50px;
  z-index: 1000;
}
// vertical nav

.vertical-nav{
  .nav{
      .nav-link{
          padding: 24px 16px;
          text-align: center;
          margin-bottom: 8px;
          .nav-icon{
              font-size: 24px;
          }
      }
  }
}

@media (max-width: 731px) {
  .nav-pills {
    flex-wrap: nowrap;
    // overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    // scrollbar-width: none; /* Hide scrollbar in Firefox */
    // -ms-overflow-style: none; /* Hide scrollbar in IE 10+ */
  }
  .nav-item {
    display: inline-block !important;
  }
 
}

@media screen and (max-width: 856px) {

  .navtab-bg .nav-link {
    font-size: 10px;
    line-height: 1;
  }

}


@media only screen and (max-width: 731px) {
  .navtab-bg li {
    min-width: 80px;
  }
  .navtab-bg .nav-link {
    display: block;
   min-width: 100%;

  }
  .nav-bg {
    min-width:200px;
  }
  
  
}

@media only screen and (max-width: 520px) {
  .navtab-bg li {
    min-width: 80px;
  }
  .navtab-bg .nav-link {
    display: block;
    width: 100%;

  }
}

@media only screen and (max-width: 470px) {
  .navtab-bg li {
    min-width: 80px;
  }
  .navtab-bg .nav-link {
    display: block;
    width: 100%;

  }
}

