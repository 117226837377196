// .map-container{
//     height: 35vw;
// }
.gm-style .gm-style-iw-c{
    border-radius: 25px;
    // width:20%;
    overflow: hidden;
    // width: 100%;
    width: 15vw;
    padding: 6px 12px 6px 12px;
}
.gm-ui-hover-effect {
    display: none !important;
}
.gm-style .gm-style-iw-d {
    overflow: hidden;
}
.map-loc-country{
    font-size: larger;
    font-weight: 600;
}
.map-loc-region{
    font-size: 12px;
    font-weight: 400;
}
.ssicon-map{
    font-size: 27px;
    color: #A6C17E;
    padding-left: 12px;
}
@media screen and (max-width: 500px) {
    .map-loc-country{
        display:none;
    }
    .map-loc-region{
        display:none;
    }
}
.custom-div-icon {
    width: 30px;
    height: 42px;
    position: relative;
}
.marker-background {
    width: 100%;
    height: 100%;
}
.marker-value {
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight:700;
    font-size: 12px;
}
.loader-overlay {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 85vh;
}
.map-legend-item {
    display: flex;
    align-items: center; /* Center items vertically */
}
.map-legend-item img {
    margin-right: 8px; /* Adjust the spacing between image and text */
}

.map_popup .leaflet-popup-content-wrapper{
    background-color: #fff;
    color: #fff;
}

