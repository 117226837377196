// 
// Contacts.scss
//

.contact-links{
  a{
    // color: $body-color;
    color: #556ee6;
  }
}

// profile

.profile-user-wid{
  margin-top: -26px;
}

.contact-container{
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  // font-size: 18px;
  outline: none;
  transition: border-color .2s;
}

.message{
  font-size: 13px;
}

.send-button{
  width:100%;
  display: inline-block;
  background-color: #90BE83;
  border: none;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
      
}

.modal-content {
  text-align: justify;
  border-radius: 15px !important;
}

.modal-body{
  border-radius: 15px !important;
}


.login-button{
  background-color: #90BE83;
  border: none;
  transition: all 0.5s;
  cursor: pointer;
      
}
.invalid-tip{
  color: #d32a1e;
  font-size: 10px;
}

.submit-success {
  display: none;
}

.submit-success[data-submit="success"] {
  display: block;
}

.submit-error {
  display: none;
}

.submit-error[data-submit="error"] {
  display: block;
}

.cont-success{
  text-align: center;
}

.email-suc{
  width: 50%;
  height:50%;
}

.continue-btn{
  width:100%;
  display: inline-block;
  background-color: #90BE83;
  border: none;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.contact-us-card{
  padding-top: 70px;
}
.card-substit{
 padding-left: 25px;
}

.contact-us-header{
  text-align: center;
  padding-top: 4%;
}