

.image-container{
    margin-top: 30px;
}
.page-content-web {
    padding: calc(#{$header-height} + #{$grid-gutter-width})
      calc(#{$grid-gutter-width} / 2) $footer-height
      calc(#{$grid-gutter-width} / 2);
      
      
      ;
  }