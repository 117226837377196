.signin-container{
    position: center;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    width: 50%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f6f6f6;
    border-radius: 0.4rem;
    outline: 0;
}

.or-letter{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #ced4da; 
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.or-letter span{
    background:#fff; 
    padding:0 5px;
    width: 100%; 
}

.fb-btn{
    // width: 100%;
    // background-color: #ffffff;
    // outline: 0;
    
    // border-radius: 0.1em;
    width: 100%;
    height:45px;
    background-color: #ffffff;
    color: black;
    border: 1px solid #f6f6f6;
    border-radius: 1em;
    outline: 0;
    margin: 5px;
    display: inline block;
    background-clip: padding-box;
    text-align: left;
    transition: all 0.5s;
}
.fb-btn{
    i{
        color: #556ee6;
        height: 10px;
    }
}

.social-fb{
    width:15px;
    height:15px;
}

.sgn-in {
    position: absolute;
    top: 0;
    right: 0;
  }

  .face-btn{
    width: 100%;
    height:45px;
    background-color: #ffffff;
    color: black;
    border: 1px solid #f6f6f6;
    border-radius: 1em;
    outline: 0;
    margin: 5px;
    display: inline block;
    background-clip: padding-box;
    text-align: left;
    transition: all 0.5s;
  }


.face-btn a {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }